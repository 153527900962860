import React, {useEffect, useState} from "react";
import {LogoHeader} from "../layouts/LogoHeader";
import {
    Box,
    Button,
    capitalize,
    Card,
    Chip,
    Collapse,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useTheme,
    Zoom
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import CustomBackdrop from "../components/CustomBackdrop";
import {useTranslation} from "react-i18next";
import {conferenceId, toolpressServerUrl} from "../configuration";
import {ArrowRight, CameraEnhance} from "@mui/icons-material";
import UserFoto from "../components/UserFoto";
import {customTheme} from "../theme/customTheme";
import {emojisplosion} from "emojisplosion";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Form = () => {
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen * 2.5,
        exit: theme.transitions.duration.leavingScreen * 2.5,
    };

    const fabStyle = {
        position: 'absolute',
        bottom: 24,
        right: 16,
    };
/**/
    const leftFabStyle = {
        position: 'absolute',
        bottom: 24,
        left: 16,
    };

    const {t} = useTranslation()
    const [fields, setFields] = useState([])
    let navigate = useNavigate()
    const [values, setValues] = useState({
        domanda: ''
    })
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    useEffect(() => {
        axios.get(toolpressServerUrl + '/conference/' + conferenceId + '/columns')
            .then(response => {
                const obj = {}
                setFields(response.data)
                response.data.map(r => obj[r] = '')
                setValues({...values, ...obj})
                setLoading(false)
            })
            .catch(() => {
                navigate("/", {replace: true})
            })
    }, [])

    const handleChangeValue = (e, key) => {
        setValues({...values, [key]: e.target.value})
    }

    const postQuestions = async () => {
        setSending(true)
        await axios.post(toolpressServerUrl + '/question/' + conferenceId, {question: values})
        setSending(false)
        setValues(prevState => ({...prevState, domanda: ''}))
    }

    const sendQuestion = (e) => {
        e.preventDefault()

        postQuestions()
            .then(() => {
                setSuccess(true)
                setOpen(true)
                setTimeout(() => setOpen(false),5000)
            })
            .catch(() => setError(true))

    }

    const handleSetError = (bool) => {
        setSuccess(bool)
    }

    function handleFoto() {
        setOpenDialog(true)
    }

    function handleClose() {
        setOpenDialog(false)
    }

    async function showApplausi(e, id) {
        let em =["👏"];
        emojisplosion({
            emojiCount: 3,
            emojis: em,
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
            physics: {
                fontSize: {
                    min: 21,
                    max: 32
                },
                /*gravity: 0.5,
                initialVelocities: {
                    x: {
                        min: -7,
                        max: 7
                    },
                    y: {
                        min: -40,
                        max: -35
                    },
                },*/
            }
        })
    }


    return (
        <LogoHeader>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => handleSetError(false)}>
                <Alert onClose={() => handleSetError(false)} severity="error" sx={{width: '100%'}}>
                    {t('errors.generic')}
                </Alert>
            </Snackbar>
            <CustomBackdrop open={loading || sending}/>
            <Container maxWidth={'sm'}>
                <Typography sx={{mt:1}} variant={"h5"} fontWeight={'bold'} textTransform={'uppercase'} color={'#fff'}>
                    {t('sendYourQuestion')}
                </Typography>
                <Typography sx={{mt:1}} variant={"body2"} color={'#fff'}>
                    {t('subtitle')}
                </Typography>
                <Card variant={'domanda'}>
                    <form style={{padding: '0rem 1rem', textAlign: 'left'}} onSubmit={sendQuestion}>
                        <Stack direction={"column"} spacing={2}>
                            {fields.map((f,index) =>
                                <Box key={index}>
                                    <TextField sx={{width: '100%'}} variant={"standard"}
                                               label={capitalize(f)}
                                               value={values[f]}
                                               onChange={(e) => handleChangeValue(e, f)}
                                    />
                                </Box>
                            )}
                            <Box sx={{width: '100%'}}>
                                <TextField sx={{
                                    width: '100%',
                                    marginTop: 4,
                                }} variant={"outlined"}
                                   multiline
                                   label={t('question')}
                                   maxRows={5}
                                   minRows={5}
                                   value={values['domanda']}
                                   onChange={(e) => handleChangeValue(e, 'domanda')}
                                />
                            </Box>
                            <Box sx={{textAlign: 'center'}}>
                                <Collapse in={open}>
                                    <Typography>
                                        {t('questionSentSuccessfully')}
                                    </Typography>
                                </Collapse>
                                <Button sx={{my: 2, width: '100%'}} variant={"contained"}
                                        type={"submit"}
                                        disabled={!values.domanda}
                                >
                                    {success ? t('sendAnotherQuestion') : t('sendQuestion')}
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                </Card>
            </Container>
        </LogoHeader>
    )
}
