export const conferenceId = "YHIGJBHRh0rlrBT6Bljm"

export const toolpressServerUrl = 'https://api-tool-press-yn26wrnl4q-ey.a.run.app'
export const serverUrlFoto = 'https://barilla-api-interazione-fujysnvipq-ew.a.run.app'
export const bucketUrl = 'https://storage.googleapis.com/barilla-photos';

/** DOC */
// https://barilla-api-interazione-fujysnvipq-ew.a.run.app/api-v2/

/** cambiare il bg direttamente da index.css */
export const bg = '/assets/bg.png'

/** prendere info da api */
export const appName = "Barilla"

export const PRIMARY_COLOR = '#b31732'
export const ACCENT_COLOR = '#b31732'
export const BG_COLOR = '#213A72'

export const MAX_PHOTOS = 81
